import styled from "styled-components";

export const Footer = styled.div`
  margin-top: 100px;
  width: 100%;
  min-height: 80px;
  background-color: #e2d7bf;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1080px) {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FirstLine = styled.div`
  width: 100%;
  min-height: 80px;
  background-color: #e2d7bf;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1080px) {
    height: 200px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SecondLine = styled.div`
  width: 100%;
  min-height: 80px;
  background-color: #e2d7bf;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1080px) {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
