import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../pages/Home";
// import Schedule from "../pages/Schedule";
// import Tickets from "../pages/Tickets";
// import Map from "../pages/Map";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"/inicio"} />,
  },
  {
    path: "/inicio",    
    element: <Home />,
  },
  // {
  //   path: "/programacao",
  //   element: <Schedule />,
  // },
  // {
  //   path: "/mapa",
  //   element: <Map />,
  // },
  // {
  //   path: "/ingressos",
  //   element: <Tickets />,
  // },
]);
