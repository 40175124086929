import { FirstLine, Footer, SecondLine } from "./styles";
import MediaPartnerFooter from "../mediaPartnerFooter";
import PerformedByFooter from "../performedByFooter";
import SponsorshipFooter from "../sponsorshipFooter";
import MusicianPartnerFooter from "../musicianPartnerFooter";
import SupportPartnerFooter from "../supportPartnerFooter";

const FooterComponent = () => {
  return (
    <Footer>
      {/* <FirstLine>
        <SponsorshipFooter />
        <MediaPartnerFooter />
        <MusicianPartnerFooter />
      </FirstLine>
      <SecondLine>
        <SupportPartnerFooter />
        <PerformedByFooter />
      </SecondLine> */}
        <PerformedByFooter />
    </Footer>
  );
};

export default FooterComponent;
