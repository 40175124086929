import React, { useState } from "react";
import logoImg from "../../assets/images/logos/LPL-24.png";
// import Countdown from "../../components/countdown";
import FooterComponent from "../../components/footer";
// import Header from "../../components/header";
// import MainPageEventText from "../../components/mainPageEventText";
// import MainPageLineUp from "../../components/mainPageLineUp";
// import MainPageTitle from "../../components/mainPageTitle";
import {
  CenterContent,
  Container,
  FirstContentBox,
  ImageBox,
  // LineUpBox,
  // SecondContentBox,
  // DateText,
  MainPageText,
} from "./styles";

const Home = () => {
  // const [deadline] = useState("April, 16, 2023");

  return (
    <>
      <Container>
        {/* <Header></Header> */}
        <CenterContent>
          {/* <MainPageTitle
            text={`GOVERNO DO ESTADO DO RIO DE JANEIRO, SECRETARIA DE ESTADO DE CULTURA E
        ECONOMIA CRIATIVA E BUDWEISER APRESENTAM`}
          /> */}
          <FirstContentBox>
            <div>
              <ImageBox>
                <img src={logoImg} alt="logo" />
                {/* <Countdown deadline={deadline} /> */}
              </ImageBox>
            </div>
          </FirstContentBox>
          <MainPageText>
            <h3>
              <div>{`EM BREVE, A LAPA VAI PULSAR AINDA MAIS FORTE.`}</div>
            </h3>
            <h3 style={{fontSize: "60px"}}>
              <div>{`AGUARDE!`}</div>
            </h3>
          </MainPageText>

          {/* <DateText>
            <h3>16/04/2023</h3>
          </DateText>
          <LineUpBox>
            <MainPageLineUp />
          </LineUpBox>

          <SecondContentBox>
            <MainPageEventText />
          </SecondContentBox> */}
        </CenterContent>
        <FooterComponent />
      </Container>
    </>
  );
};

export default Home;
