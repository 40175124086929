import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  width: 100%;
  font-family: "EncodeSans";
  font-weight: 900;
  font-size: 12px;
  color: #2c3442;
  letter-spacing: 2px;
  margin-right: 50px;
  @media (max-width: 1080px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
`;

export const MusicianPartnerImgVirginMusic = styled.img`
  max-width: 70px;
  width: 100%;
`;
