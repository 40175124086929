import React from "react";
import olgaImg from "../../assets/images/logos/olga.svg";
import v3aImg from "../../assets/images/logos/v3a.svg";
import srcomImg from "../../assets/images/logos/srcom.svg";
import {
  Container,
  PerformedByImgOlga,
  PerformedByImgSRcom,
  PerformedByImgV3A,
} from "./styles";

const PerformedByFooter = () => {
  return (
    <Container>
      <span>REALIZAÇÃO</span>
      <PerformedByImgOlga src={olgaImg} alt="Logo Olga" />
      <PerformedByImgV3A src={v3aImg} alt="Logo V3A" />
      <PerformedByImgSRcom src={srcomImg} alt="Logo SRCOM" />
    </Container>
  );
};

export default PerformedByFooter;
