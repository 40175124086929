import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  width: 100%;
  font-family: "EncodeSans";
  font-weight: 900;
  font-size: 12px;
  color: #2c3442;
  letter-spacing: 2px;
  margin-right: 50px;
  @media (max-width: 1080px) {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }
`;

export const SponsorshipImgBudweiser = styled.img`
  max-width: 100px;
  width: 100%;
`;

export const SponsorshipImgSecretariaRJ = styled.img`
  max-width: 200px;
  width: 100%;
`;
