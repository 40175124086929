import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
  font-family: "Sora";
  font-weight: 900;
  font-size: 16px;
  color: #2c3442;
  letter-spacing: 2px;
  @media (max-width: 1080px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
`;

export const PerformedByImgOlga = styled.img`
  max-width: 70px;
  width: 100%;
`;

export const PerformedByImgV3A = styled.img`
  max-width: 70px;
  width: 100%;
`;

export const PerformedByImgSRcom = styled.img`
  max-width: 120px;
  width: 100%;
`;
