import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

html {
  scroll-behavior: smooth;
}

button {
    cursor: pointer;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: linear-gradient(113.9deg, rgb(255, 7, 7) -4.5%, rgb(255, 255, 5) 98.8%);

  }
`;
