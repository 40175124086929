import styled from "styled-components";
import backgroundImg from "../../assets/images/bg-image.png";


export const Container = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(${backgroundImg});
  background-size: cover;
  min-height: 100vh;
`;

export const CenterContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 800px;
  }

  @media (max-width: 1080px) {
    margin-bottom: 40px;
  }
`;

export const LineUpBox = styled.div`
  margin-top: 40px;
`;

export const FirstContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80vw;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const SecondContentBox = styled.div`
  width: 80vw;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DateText = styled.div`
  margin: 60px 0 40px 0;
  h3 {
    color: #ffffff;
    font-size: 70px;
    font-family: "EncodeSans";
    font-weight: 900;
    text-align: center;
  }
  @media (max-width: 1080px) {
    font-size: 95%;
    margin: 40px 0 40px 0;
  }
`;

export const MainPageText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px 0 40px 0;

  h3 {
    font-size: 50px;
    font-family: "Sora";
    font-weight: 900;
    text-align: center;
    width: fit-content;

    div {
      position: relative;
      padding: 20px;
      color: white;
      margin: 0 50px;
      z-index: 1;
      color: #123449;

      &::before {
        content: "";
        position: absolute;
        top: -10px;
        left: -10px;
        width: calc(100% + 15px);
        height: calc(100% + 10px);
        background-color: #e0d5bd;
        z-index: -1;
        transform: skew(-10deg);
      }
    }
  }
  @media (max-width: 1080px) {
    font-size: 95%;
    margin: 40px 0 40px 0;
    width: 700px;

    h3 {
      div {
        margin: 15px;
      }
    }
  }
`;
